// Cache all parallax elements to avoid further queries
const parallaxContainers = document.querySelectorAll('.parallax-container');
const parallaxLayers = document.querySelectorAll('.parallax-layer');

// Increase size of element in order to prevent any exposed gap on scroll
const adjustLayerHeight = () => {
  parallaxContainers.forEach(container => {
    const containerHeight = container.offsetHeight * 1.75;
    parallaxLayers.forEach(layer => {
      layer.style.height = containerHeight + 'px';
    });
  });
};

// Prevent parallax from running until item is close to viewport to help elements further down the page
const isElementInViewport = el => {
  const rect = el.getBoundingClientRect();
  return rect.top <= window.innerHeight + 50 && rect.bottom >= 0;
};

// Parallax movement:
// Multiple layers within the same container will offset speeds from each other based on order. \
// Each parallax container moves relative to where it is in the rendered HTML document
const parallaxScroll = () => {
  const scrollTop = window.scrollY;
  parallaxContainers.forEach(element => {
    const distanceFromTop = element.offsetTop;
    if (isElementInViewport(element)) {
      const scrollDistance = scrollTop - distanceFromTop;
      parallaxLayers.forEach((layer, index) => {
        const yPos = scrollDistance * (index + 1) * 0.2;
        layer.style.transform = `translateY(${yPos}px)`;
      });
    }
  });
};

// Remove on Mobile for performance
const isMobileOrTablet = () => {
  return window.innerWidth <= 1024;
};

// Listen & run relevant functions on load, scroll, and resize
window.addEventListener('load', () => {
  const parallaxLayersExist = parallaxLayers.length > 0;
  if (parallaxLayersExist && !isMobileOrTablet()) {
    adjustLayerHeight();
    window.addEventListener('resize', adjustLayerHeight);
    window.addEventListener('scroll', parallaxScroll);
  }
});
